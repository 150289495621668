import fetchRequest from './fetchRequest'

/**
 * Helper for requests
 * @param {string} url The url to request
 * @param {string} csrfToken The CSRF token to send with the request
 */
const doApiGetRequest = async (url, csrfToken) => {
    // Request the data from the api
    const responce = await fetchRequest(url, csrfToken)

    if (!responce.ok) return Promise.reject(responce)
    return Promise.resolve(responce)
}

/**
 * Check the users auth state with the server
 */
export const getUserAuth = async (startSession = false) => {
    return doApiGetRequest(`user/auth?startSession=${startSession}`)
}

/**
 * Get the manufacturers data
 */
export const getManufacturers = async (activeOnly = true) => {
    return doApiGetRequest(`manufacturers?activeOnly=${activeOnly}`)
}

/**
 * Get the manufacturer data by id
 */
export const getManufacturerById = async (manufacturerId, activeOnly = true) => {
    return doApiGetRequest(`manufacturer/${encodeURIComponent(manufacturerId)}?activeOnly=${activeOnly}`)
}

/**
 * Get the manufacturer data by id
 */
export const getManufacturerByRoute = async (manufacturerRoute, activeOnly = true) => {
    return doApiGetRequest(`manufacturer/route/${encodeURIComponent(manufacturerRoute)}?activeOnly=${activeOnly}`)
}

/**
 * Get the manufacturer section data from the route
 */
export const getManufacturerSectionById = async (sectionId, activeOnly = true) => {
    return doApiGetRequest(`section/${encodeURIComponent(sectionId)}?activeOnly=${activeOnly}`)
}

/**
 * Get the manufacturer section data from the route
 */
export const getManufacturerSectionByRoute = async (manufacturerRoute, sectionRoute, activeOnly = true) => {
    return doApiGetRequest(
        `section/route/${encodeURIComponent(manufacturerRoute)}/${encodeURIComponent(
            sectionRoute
        )}?activeOnly=${activeOnly}`
    )
}

/**
 * Get the section item data from the route
 */
export const getSectionItemById = async (itemId, activeOnly = true) => {
    return doApiGetRequest(`item/${encodeURIComponent(itemId)}?activeOnly=${activeOnly}`)
}
