import React from 'react'
import Drawer from '@mui/material/Drawer'
import Box from '@mui/material/Box'
import KeyboardArrowLeftIcon from '@mui/icons-material/KeyboardArrowLeft'
import { NavLink } from 'react-router-dom'

import getNavLinks from '../../helpers/getNavLinks'
import useAppContext from '../../context/useAppContext'
import './NavDrawer.css'

function NavDrawer() {
    const { manufacturers, drawerLeftIsOpen, toggleDrawerLeft } = useAppContext()
    const { isLoading, isError, data } = manufacturers

    return (
        <Drawer open={drawerLeftIsOpen} onClose={() => toggleDrawerLeft(false)} anchor='left'>
            <Box
                sx={{ minWidth: '200px' }}
                role='presentation'
                onClick={() => toggleDrawerLeft(false)}
                // onKeyDown={toggleDrawer(false)}
            >
                <div className='padding text-right surface on-surface-text dl-close-bar'>
                    <div className='margin padding-right dl-close-icon dl-link'>
                        <KeyboardArrowLeftIcon fontSize='large' />
                    </div>
                </div>

                <div className='dl-item dl-link'>
                    <NavLink to='/'>Home</NavLink>
                </div>

                {isLoading || isError
                    ? null
                    : data?.data?.map((item) => {
                          const navlinks = getNavLinks(item)

                          return (
                              <div key={item.id}>
                                  <div className='dl-item dl-item-header'>
                                      <div>{item.title}</div>
                                  </div>
                                  {navlinks?.map((subitem) => {
                                      return (
                                          <div key={subitem.id} className='dl-item dl-sub-item dl-link'>
                                              <NavLink to={subitem.route}>{subitem.title}</NavLink>
                                          </div>
                                      )
                                  })}
                              </div>
                          )
                      })}
            </Box>
        </Drawer>
    )
}

export default NavDrawer
