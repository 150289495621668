import React from 'react'
import { Skeleton } from '@mui/material'

import useAppContext from '../../context/useAppContext'
import './Footer.css'

function Footer() {
    const placeHolders = [...Array(6).keys()]

    const { manufacturers, setActiveManufacturersId } = useAppContext()
    const { isLoading, isError, data } = manufacturers

    const onMouseEnter = (id) => {
        setActiveManufacturersId(id)
    }

    return (
        <div className='app-footer flex-container flex-center-items flex-row border-top-primary-4'>
            {isLoading || isError
                ? placeHolders?.map((number) => {
                      return (
                          <div className='flex-1' key={number}>
                              <Skeleton sx={{ bgcolor: '#ffffffaa' }} variant='rectangular' animation='wave' width={'100%'} height={118} />
                          </div>
                      )
                  })
                : data?.data?.map((item) => {
                      if (!item?.logo_image) return null

                      return (
                          <div className='flex-1 margin padding flex-center-self' key={item.id} onMouseEnter={() => onMouseEnter(item.id)}>
                              <img src={item?.logo_image} alt={item?.title} className='responsive center' />
                          </div>
                      )
                  })}
        </div>
    )
}

export default Footer
