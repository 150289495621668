import { useQuery } from '@tanstack/react-query'

import {
    getManufacturerById,
    getManufacturers,
    getManufacturerSectionById,
    getManufacturerSectionByRoute,
    getSectionItemById,
} from '../requests/getRequests'

/**
 * Key used in tan stack query
 */
export const manufacturersKey = 'manufacturers'
export const manufacturerKey = 'manufacturer'
export const manufacturerSectionKey = 'manufacturerSection'
export const manufacturerSectionItemKey = 'manufacturerSectionItem'

const noKey = '__none'

/**
 * @returns react-query Hooks
 */

/**
 * Get the Manufacturers list
 */
export function useManufacturersQuery(activeOnly = true) {
    return useQuery({
        queryKey: [manufacturersKey, activeOnly],
        queryFn: () => getManufacturers(activeOnly),
    })
}

/**
 * Get the data for a single Manufacturer
 */
export function useManufacturerByIdQuery(manufacturerId, activeOnly = true) {
    const id = !manufacturerId || `${manufacturerId}`.trim() === '' ? noKey : manufacturerId
    return useQuery({
        queryKey: [manufacturerKey, id, activeOnly],
        queryFn: () => getManufacturerById(id, activeOnly),
    })
}

/**
 * Gets info about a single Manufacturer Section
 */
export function useManufacturerSectionByIdQuery(sectionId, activeOnly = true) {
    const sId = !sectionId || `${sectionId}`.trim() === '' ? noKey : sectionId

    return useQuery({
        queryKey: [manufacturerSectionKey, sId, activeOnly],
        queryFn: () => getManufacturerSectionById(sId, activeOnly),
    })
}

/**
 * Gets info about a single Manufacturer Section
 */
export function useManufacturerSectionByRouteQuery(manufacturerRoute, sectionRoute) {
    const id = !manufacturerRoute || `${manufacturerRoute}`.trim() === '' ? noKey : manufacturerRoute
    const sId = !sectionRoute ? noKey : sectionRoute

    return useQuery({
        queryKey: [manufacturerSectionKey, id, sId],
        queryFn: () => getManufacturerSectionByRoute(id, sId),
    })
}

/**
 * Gets info about a single Section Item
 */
export function useSectionItemByIdQuery(itemId, activeOnly = true) {
    const iId = !itemId || `${itemId}`.trim() === '' ? noKey : itemId

    return useQuery({
        queryKey: [manufacturerSectionItemKey, iId, activeOnly],
        queryFn: () => getSectionItemById(iId, activeOnly),
    })
}
