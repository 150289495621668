import React from 'react'

import './LoadingSpinner.css'

function LoadingSpinner(fillSpace = false) {
    const boxSize = fillSpace ? 'height-100 flex-center-items flex-container' : ''
    return (
        <div className={`${boxSize}`}>
            <div className='lds-bars'>
                <div></div>
                <div></div>
                <div></div>
            </div>
        </div>
    )
}

export default LoadingSpinner
