import React from 'react'
import { Routes, Route, useLocation } from 'react-router-dom'

import IndexPage from '../pages/IndexPage'
import ComingSoonPage from '../pages/ComingSoonPage'
import NotFound404 from '../pages/NotFound404'
import MetaData from '../components/MetaData'
import ManufacturerSectionPage from '../pages/ManufacturerSectionPage'

function MainRoute() {
    const location = useLocation()

    return (
        <>
            <MetaData noIndex={false} title={process.env.REACT_APP_SITE_NAME} description={process.env.REACT_APP_SITE_TAG_LINE} />
            <Routes location={location} key={location.pathname}>
                <Route path='/' element={<IndexPage />} />
                <Route path='coming-soon' element={<ComingSoonPage />} />
                <Route path=':manufacturerRoute/:sectionRoute/:sectionSlug?' element={<ManufacturerSectionPage />} />
                <Route path='*' element={<NotFound404 />} />
            </Routes>
        </>
    )
}

export default MainRoute
