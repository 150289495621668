import { createContext, useState } from 'react'

import { useManufacturersQuery } from '../hooks/useRequestQuery'

const AppContext = createContext()

export const AppProvider = ({ children }) => {
    const [activeManufacturersId, doSetActiveManufacturersId] = useState(undefined)
    const [drawerLeftIsOpen, setDrawerLeftIsOpen] = useState(false)

    const manufacturers = useManufacturersQuery()

    /**
     * Set the active index
     */
    const setActiveManufacturersId = (id) => {
        if (id !== undefined) {
            doSetActiveManufacturersId(id)
        }
    }

    /**
     * Toggle the drawer open / close
     */
    const toggleDrawerLeft = (state) => {
        if (typeof state == 'boolean') {
            setDrawerLeftIsOpen(state)
        } else {
            setDrawerLeftIsOpen((currentState) => !currentState)
        }
    }

    return (
        <AppContext.Provider
            value={{
                manufacturers,
                activeManufacturersId,
                drawerLeftIsOpen,
                setActiveManufacturersId,
                toggleDrawerLeft,
            }}
        >
            {children}
        </AppContext.Provider>
    )
}

export default AppContext
