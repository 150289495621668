import React from 'react'

import { motion } from 'framer-motion'

function ContentWrapper({ className = '', children }) {
    // animated page transition
    return (
        <motion.div
            className={`flex ${className} width-100`}
            initial={{ opacity: 0 }} //  represent the initial state of the animation
            animate={{ opacity: 100 }} // animate the Motion component to this state after mounting.
            exit={{ opacity: 0 }} // fired just before the Motion component exit (removed from the dom)
            transition={{ from: 0, duration: 1, ease: 'linear' }} // the transition effect between initial and exit
        >
            {children}
        </motion.div>
    )
}

export default ContentWrapper
