import React from 'react'

import MetaData from '../components/MetaData'
import ContentWrapper from '../components/ContentWrapper'

function NotFound404() {
    return (
        <ContentWrapper>
            <MetaData noIndex={true} title='404 - page not found' />
            <div className='flex-center-self center'>
                <div className='flex-container flex-row flex-center-items'>
                    <span className='flex-0 headline-large padding border-right-primary-4 flex-center-self'>404</span>
                    <span className='title-medium padding flex-center-self'>This page could not be found.</span>
                </div>
            </div>
        </ContentWrapper>
    )
}

export default NotFound404
