import React from 'react'
import ReactDOM from 'react-dom/client'
import { createTheme, ThemeProvider } from '@mui/material/styles'
import { BrowserRouter } from 'react-router-dom'
import { HelmetProvider } from 'react-helmet-async'
import { QueryClient, QueryClientProvider } from '@tanstack/react-query'

import './css/reset.css'
import './css/variables.css'
import './css/index.css'
import './css/components.css'
import 'typeface-roboto'

import ErrorBoundary from './components/errors/ErrorBoundary'
import { themeOptionsDark } from './Theme'
import App from './App'
import reportWebVitals from './reportWebVitals'
import { AppProvider } from './context/AppContext'
import ErrorBlock from './components/errors/ErrorBlock'

// Create the helment context
const helmetContext = {}

// Create the app context
const appContext = {}

// Create a query client
const queryClient = new QueryClient()

// Create a them e
const theme = createTheme(themeOptionsDark)

const root = ReactDOM.createRoot(document.getElementById('root'))
root.render(
    // eslint-disable-next-line react/jsx-filename-extension
    <React.StrictMode>
        <ErrorBoundary fallback={<ErrorBlock useLargeFonts={true} fillSpace={true} />}>
            <BrowserRouter>
                <QueryClientProvider client={queryClient}>
                    <HelmetProvider context={helmetContext}>
                        <AppProvider context={appContext}>
                            <ThemeProvider theme={theme}>
                                <App />
                            </ThemeProvider>
                        </AppProvider>
                    </HelmetProvider>
                </QueryClientProvider>
            </BrowserRouter>
        </ErrorBoundary>
    </React.StrictMode>
)

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals()
