// colors taken from tokens.css

export const themeOptionsDark = {
    palette: {
        mode: 'dark',
        primary: {
            main: '#40e091',
        },
        secondary: {
            main: '#ffb68a',
        },
        error: {
            main: '#d32f2f',
        },
        background: {
            default: '#1a1c19',
            paper: '#1a1c19',
        },
        text: {
            primary: '#e2e3dd',
        },
    },
}
