import React, { Fragment } from "react"
import { Helmet } from "react-helmet-async"

/**
 * Creates a single meta tag
 * @param {string|undefined} name the name to use use or undefined
 * @param {string|undefined} property the property to use use or undefined
 * @param {string|undefined} content the content to use, to empty no tag will be rendered
 * @param {bool} noIndex
 * @returns
 */
function metaTag(name, property, content, noIndex = false) {
    if (noIndex || !content) return null
    if (name) return { name, content }
    if (property) return { property, content }
    return null
}

function MetaData({
    title = undefined,
    description = undefined,
    noIndex = false,
    keywords = [],
    pageType = undefined,
    pageImage = undefined,
    canonicalUrl = undefined,
    siteName = undefined,
}) {
    //facebook tags
    //@see https://developers.facebook.com/docs/sharing/webmasters/
    const url = canonicalUrl ? canonicalUrl : window.location.href
    const image = pageImage ? pageImage : `${process.env.REACT_APP_SITE_URI}logo512.png`
    const pType = pageType ? pageType : "website"

    const robots = noIndex ? "noindex, noimageindex, nofollow, noarchive, nocache, nosnippet, noodp, noydir" : "index, follow"

    // NOTE: the below will be converted into <meta/> tags
    // maybe better we should use useMemo here?
    // .filter((n) => n)  at the end if to remove nulls
    const metaTags = [
        // Basic meta tags
        metaTag("description", undefined, description, noIndex),
        metaTag("keywords", undefined, keywords?.join(", ")),
        metaTag("robots", undefined, robots),
        // Facebook open graph
        metaTag(undefined, "og:url", url, noIndex),
        metaTag(undefined, "og:type", pType, noIndex),
        metaTag(undefined, "og:title", title, noIndex),
        metaTag(undefined, "og:description", description, noIndex),
        metaTag(undefined, "og:image", image, noIndex),
        metaTag(undefined, "og:site_name", siteName, noIndex),
        // Twitter meta tags
        // domain is example.com (without https:// or path)
        metaTag(undefined, "twitter:domain", process.env.REACT_APP_SITE_DOMAIN, noIndex),
        metaTag("twitter:card", undefined, "summary", noIndex),
        metaTag(undefined, "twitter:url", url, noIndex),
        metaTag("twitter:title", undefined, title, noIndex),
        metaTag("twitter:description", undefined, description, noIndex),
        metaTag("twitter:image", undefined, image, noIndex),
    ].filter((n) => n)

    // test this at - https://www.opengraph.xyz/
    return <Helmet meta={metaTags} title={title ? title : undefined} />
}

export function AdminMetaData() {
    return (
        <>
            <MetaData />
            <Helmet>
                <title>Admin</title>
                <meta name='robots' content='noindex, noimageindex, nofollow, noarchive, nocache, nosnippet, noodp, noydir' />
            </Helmet>
        </>
    )
}

export default MetaData
