/**
 * Converts the passed error to a string message
 * By looking at the common places that a error message can be found
 */
const getErrorString = (error, defaultError = undefined) => {
    // check for strings
    if (error !== undefined) {
        if (typeof error === 'string') return error
        if (typeof error.message === 'string') return error.message
        if (typeof error.messages === 'string') return error.messages
        if (typeof error.error === 'string') return error.error

        // check for arrays
        if (Array.isArray(error)) return error?.join('\n')
        if (Array.isArray(error.message)) return error.message?.join('\n')
        if (Array.isArray(error.messages)) return error.messages?.join('\n')
        if (Array.isArray(error.error)) return error.error?.join('\n')

        // check for objects
        if (typeof error.error === 'object' && error.error !== null) {
            return getErrorString(error.error, defaultError)
        }

        if (typeof error.messages === 'object' && error.messages !== null) {
            return getErrorString(error.messages, defaultError)
        }

        if (typeof error === 'object' && error !== null) {
            return Object.values(error)?.join('\n')
        }
    }

    // just use the error
    return defaultError ? defaultError : error ? error.toString() : 'an undefined error occurred'
}

export default getErrorString
