import React from 'react'
import Tooltip from '@mui/material/Tooltip'
import { IconButton as MuiIconButton } from '@mui/material'

function IconButton({ title, children, color = 'default', className = '', onClick = undefined }) {
    return (
        <MuiIconButton className={className} aria-label={title} component='span' color={color} onClick={onClick}>
            <Tooltip title={title}>{children}</Tooltip>
        </MuiIconButton>
    )
}

export default IconButton
