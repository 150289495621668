import React, { useEffect, useState } from 'react'

import './IndexPage.css'

import MetaData from '../components/MetaData'
import LoadingSpinner from '../components/LoadingSpinner'
import ErrorBlock from '../components/errors/ErrorBlock'
import useAppContext from '../context/useAppContext'
import ContentWrapper from '../components/ContentWrapper'

function CarouselItem({ image, currentIndex }) {
    return (
        <>
            <div
                className='carousel-item'
                style={{ transform: `translate(-${currentIndex * 100}%)`, backgroundImage: `url("${image}")` }}
            >
                {/*
                <div className='flex-center-self center surface padding' style={{ opacity: '80%' }}>
                    <div className='flex-container flex-row flex-center-items'>
                        <span className='flex-0 on-surface-text headline-large padding border-right-primary-4 flex-center-self'>Coming Soon</span>
                        <span className='title-medium on-surface-text padding flex-center-self'>{process.env.REACT_APP_SITE_NAME}</span>
                    </div>
                </div>
                */}
            </div>
        </>
    )
}

function IndexPage() {
    const [currentIndex, setCurrentIndex] = useState(0)
    const [splashImages, setSplashImages] = useState([])

    const { manufacturers, activeManufacturersId, setActiveManufacturersId } = useAppContext()
    const { isLoading, isError, data } = manufacturers

    /**
     * Moves the carousel to the next index
     */
    const carouselInfiniteScroll = () => {
        if (!splashImages) return

        if (currentIndex >= splashImages.length - 1) {
            return setCurrentIndex(0)
        }
        return setCurrentIndex(currentIndex + 1)
    }

    /**
     * Scroll the carousel to the passed index
     */
    const scrollToIndex = (index) => {
        if (!splashImages) return

        if (index >= splashImages.length || index < 0) {
            return setCurrentIndex(0)
        }
        return setCurrentIndex(index)
    }

    /**
     * Update the splashImages from the data
     */
    const fillSplashImages = (activeManufId) => {
        const firstForManuf = new Map()
        const justForManuf = new Map()

        // loop the data to look for splash images
        data?.data?.map((item) => {
            const manufId = item?.id
            item.splash?.map((splashImage) => {
                // unpack the splash image into itemId#splashId
                const id = `${manufId}#${splashImage.id}`
                if (activeManufId === manufId) justForManuf.set(id, { id, url: splashImage.uri })
                if (!firstForManuf.has(manufId)) firstForManuf.set(manufId, { id, url: splashImage.uri })
            })
        })

        // retrun the correct array of images
        let result = [...firstForManuf.values()]
        if (justForManuf.size > 0) {
            result = [...justForManuf.values()]
        }
        setSplashImages(result)
        return result
    }

    /**
     * Start the carousel timer
     */
    useEffect(() => {
        const interval = setInterval(() => {
            carouselInfiniteScroll()
        }, 5000)
        return () => clearInterval(interval)
    })

    /**
     * Reset the active index when the page is unloaded
     */
    useEffect(() => {
        setActiveManufacturersId(undefined)
        return () => setActiveManufacturersId(undefined)
    }, [])

    /**
     * Update the current scroll item when the user hovers over a menu item
     */
    useEffect(() => {
        var filteredImages = fillSplashImages(activeManufacturersId)
        if (activeManufacturersId === undefined) return

        const index = filteredImages.findIndex((splashImage) => splashImage.id?.startsWith(activeManufacturersId))

        if (index !== -1 && activeManufacturersId !== index) {
            scrollToIndex(index)
        }
    }, [activeManufacturersId, data])

    if (isLoading)
        return (
            <div className='text-center flex-1'>
                <LoadingSpinner fillSpace={true} />
            </div>
        )

    if (isError) {
        return (
            <div className='text-center flex-1'>
                <ErrorBlock useLargeFonts={true} fillSpace={true} />
            </div>
        )
    }

    return (
        <ContentWrapper>
            <MetaData
                noIndex={false}
                title={process.env.REACT_APP_SITE_NAME}
                description={process.env.REACT_APP_SITE_TAG_LINE}
            />
            <div className='carousel-container'>
                {splashImages?.map((image) => {
                    return <CarouselItem key={image.id} image={image.url} currentIndex={currentIndex} />
                })}
                <div className='carousel-slider'>
                    {splashImages?.map((item, index) => {
                        return (
                            <div
                                key={item.id}
                                onClick={() => {
                                    scrollToIndex(index)
                                }}
                                className={index === currentIndex ? 'active' : ''}
                            />
                        )
                    })}
                </div>
            </div>
        </ContentWrapper>
    )
}

export default IndexPage
