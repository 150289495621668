export const supportedImageTypes = '.png,.jpg,.jpeg,.gif,.webp'

export const supportedFileTypes = supportedImageTypes + ',.pdf,.xls,.xlsx'

export const fileTypeConst = {
    image: 0,
    pdf: 1,
    youtube: 2,
    excel: 3,
}
Object.freeze(fileTypeConst)

/**
 * Gets the correct file type of the passed url
 * @param {string} url url to check
 * @returns
 */
export default function getFileType(url) {
    const urlLc = url.toLowerCase()

    if (urlLc.endsWith('.pdf')) return fileTypeConst.pdf
    else if (urlLc.startsWith('https://youtu.be/') || urlLc.startsWith('https://www.youtube.com/'))
        return fileTypeConst.youtube
    else if (urlLc.endsWith('.xls') || urlLc.endsWith('.xlsx')) return fileTypeConst.excel
    else return fileTypeConst.image
}

/**
 * Check if the url points to an image or not
 * @param {string} url url to check
 */
export function isImage(url) {
    const urlLc = url?.toLowerCase() ?? '.jpg' //.jpg to default to true
    return (
        urlLc.endsWith('.jpg') ||
        urlLc.endsWith('.jpeg') ||
        urlLc.endsWith('.png') ||
        urlLc.endsWith('.gif') ||
        urlLc.endsWith('.webp')
    )
}

/**
 * Checks if the passed url is a emded video url
 * @param {string} url the url to check
 * @returns true if the url is an emded url
 */
export function isEmbed(url) {
    return getFileType(`${url}`) === fileTypeConst.youtube
}

/**
 * checks if the file is a pdf file or not
 * @param {string} url the url to check
 * @returns true if the url is a pdf file
 */
export function isPdf(url) {
    const urlLc = url?.toLowerCase() ?? '' // default to false
    return urlLc.endsWith('.pdf')
}

/**
 * Chgecks if the passed csv of file types includes non-images
 * @param {string} accept the file type from the accept file
 * @returns true if the accept has none image exts
 */
export function containsNoneImages(accept) {
    const fileTypes = accept.split(',')
    const imageTypes = supportedImageTypes.split(',')
    for (let i = 0; i < fileTypes.length; i++) {
        if (!imageTypes.includes(fileTypes[i])) return true
    }
    return false
}
