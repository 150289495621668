import * as React from 'react'
import Button from '@mui/material/Button'
import Dialog from '@mui/material/Dialog'
import DialogActions from '@mui/material/DialogActions'
import DialogContent from '@mui/material/DialogContent'
import DialogContentText from '@mui/material/DialogContentText'
import DialogTitle from '@mui/material/DialogTitle'

export default function ConfirmDialog({
    isOpen,
    title,
    description,
    onYesClick,
    onNoClick,
    onClose,
    yesLabel = 'Yes',
    noLabel = 'No',
    yesDisabled = false,
    noDisabled = false,
    children,
}) {
    return (
        <Dialog open={isOpen} onClose={onClose}>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>
                <DialogContentText id='alert-dialog-description'>{description}</DialogContentText>
                {children}
            </DialogContent>
            <DialogActions>
                <Button onClick={onNoClick} disabled={noDisabled} variant='outlined'>
                    {noLabel}
                </Button>
                <Button onClick={onYesClick} autoFocus disabled={yesDisabled} variant='contained'>
                    {yesLabel}
                </Button>
            </DialogActions>
        </Dialog>
    )
}
