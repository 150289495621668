import getErrorString from '../helpers/getErrorString'

export const defaultGetOptions = {
    method: 'GET',
    credentials: 'include', // include || omit
}

export const defaultPostOptions = {
    ...defaultGetOptions,
    method: 'POST',
}

export const defaultPutOptions = {
    ...defaultGetOptions,
    method: 'POST', // 'PUT', PHP doent support multipart-forms with PUT!
}

export const defaultDeleteOptions = {
    ...defaultGetOptions,
    method: 'DELETE',
}

/**
 * The default responce from the fetch request
 */
export const defaultResponse = {
    ok: false,
    status: -1,
    data: null,
    error: undefined,
    isLoggedIn: undefined,
    csrfToken: undefined,
}

/**
 * Wrapper for fetach requests
 * parses the result into a common return type
 * @param {string} url The url to request
 * @param {string} csrfToken The CSRF token to send with the request
 * @param {object} options to request options
 * @returns object { ok: bool, status: int, data: ?object, error: ?string, isLoggedIn: ?bool }
 */
const fetchRequest = async (url, csrfToken, options = {}) => {
    const init = { ...defaultGetOptions, ...options }

    // Add the api headers
    if (csrfToken) {
        if (!init.headers) init.headers = {}
        init.headers[process.env.REACT_APP_XSRF_HEADER] = csrfToken
    }

    // Setup the default result
    const result = { ...defaultResponse }
    try {
        // Send the request to the server
        const request = await fetch(`${process.env.REACT_APP_API_URI}${url}`, init)

        // Read the request result and get the JSON
        result.ok = request.ok // true | false
        result.status = request.status // 200, 201, 404, etc

        // Read the json body
        const json = await request.json()

        // If we have json then check the result
        if (json) {
            if (result.ok) {
                // {"result":{"isLoggedIn":false,"data":[...]}
                result.data = json?.result?.data
                result.isLoggedIn = json?.result?.isLoggedIn
                result.csrfToken = json?.result?.csrfToken
            } else {
                // {"error":{"isLoggedIn":false,"messages":"no matching api routes found"}}
                result.error = getErrorString(json, request.statusText)
                result.isLoggedIn = json?.error?.isLoggedIn
                result.csrfToken = json?.error?.csrfToken
            }
        } else {
            result.ok = false
            result.error = getErrorString(request.statusText)
        }
    } catch (e) {
        // On exception, get the error and set the status to false
        result.ok = false
        result.error = getErrorString(e)
        console.error(e)
    }

    return result
}

export default fetchRequest
