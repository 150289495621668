import React, { Fragment, useEffect, useState } from 'react'
import { Skeleton } from '@mui/material'
import DownloadIcon from '@mui/icons-material/Download'
import ZoomInIcon from '@mui/icons-material/ZoomIn'
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf'
import AttachFileIcon from '@mui/icons-material/AttachFile'
import YouTubeIcon from '@mui/icons-material/YouTube'

import { ReactComponent as ExcelLogo } from '../../assets/svg/excel.svg'
import { ReactComponent as YoutubeLogo } from '../../assets/svg/youtube.svg'

import ContentWrapper from '../../components/ContentWrapper'
import MarkdownWrapper from '../../components/MarkdownWrapper'
import IconButton from '../IconButton'
import PopupDialog from '../PopupDialog'
import getFileType, { fileTypeConst } from '../../helpers/getFileType'
import YoutubePlayer from '../embeds/YoutubePlayer'

/**
 * Gets the preview url from the data object
 */
const getFileTypeFromUrl = (data) => {
    const url = data?.image ?? ''
    return getFileType(url)
}

/**
 * a single focused item
 */
function ManufacturerItemBlock({ item, childItem, isLoading }) {
    const [showPopup, setShowPopup] = useState(false)

    const titleElement = isLoading ? (
        <Skeleton variant='h2' width='50%' height='32px' />
    ) : (
        <h2 className='headline-small'>{item?.title}</h2>
    )
    const descriptionElement = isLoading ? (
        <>
            <Skeleton width='100%' height='20px' />
            <Skeleton width='80%' height='20px' />
            <Skeleton width='100%' height='20px' />
            <Skeleton width='80%' height='20px' />
        </>
    ) : (
        <MarkdownWrapper className='body-medium'>{item?.info_markdown}</MarkdownWrapper>
    )

    /**
     * Shows the image popup
     */
    const showImagePopup = (show) => {
        setShowPopup(show)
    }

    // if there is no data to show then just show an empty element
    if (!isLoading && !item) return <></>

    const imageItem = childItem ? childItem : item

    const fileName = imageItem?.image ? imageItem.image.substring(imageItem.image.lastIndexOf('/') + 1) : ''

    const fileType = getFileType(imageItem?.image ?? '')

    return (
        <>
            <PopupDialog isOpen={showPopup} title={item?.title ?? 'Image'} onClose={() => setShowPopup(false)}>
                <img src={imageItem?.image} className='responsive' alt='product' />
            </PopupDialog>
            <div className='grid-span-col-2 grid-span-col-1-sm'>
                <div className='card card-row card-column-sm width-100'>
                    <div className='card-image position-relative'>
                        {isLoading ? (
                            <div className='padding'>
                                <Skeleton
                                    variant='rectangular'
                                    animation='wave'
                                    width={'100%'}
                                    height={'100%'}
                                    sx={{ minHeight: '250px' }}
                                />
                            </div>
                        ) : fileType === fileTypeConst.image ? (
                            <>
                                <img src={imageItem?.image} className='responsive' alt='product' />
                                <div className='bottom-right'>
                                    <IconButton title='Zoom' onClick={() => showImagePopup(true)}>
                                        <ZoomInIcon fontSize='medium' />
                                    </IconButton>
                                </div>
                            </>
                        ) : fileType === fileTypeConst.pdf ? (
                            <iframe
                                src={imageItem?.image}
                                allowFullScreen={true}
                                referrerPolicy='no-referrer'
                                width='100%'
                                height='500px'
                                loading='lazy'
                            />
                        ) : fileType === fileTypeConst.youtube ? (
                            <div className='player-box'>
                                <YoutubePlayer url={imageItem?.image ?? ''} />
                            </div>
                        ) : (
                            <ExcelLogo />
                        )}
                    </div>
                    <div className='card-content'>
                        {titleElement}
                        {descriptionElement}
                        {!isLoading && fileType === fileTypeConst.pdf ? (
                            <a href={imageItem?.image} download={fileName} className='btn btn-link body-large '>
                                <DownloadIcon fontSize='small' /> Download
                            </a>
                        ) : null}
                    </div>
                </div>
            </div>
        </>
    )
}

/**
 * a grid of items that can be clicked
 */
function ManufacturerItemGrid({ listItems, selectedItem, selectedChildItem, onThumbnailClick, isLoading }) {
    // if the data is not loaded yet use a Skeleton size of 12 (4x4)
    const items = isLoading ? new Array(12).fill({}) : listItems
    return (
        <div className='grid grid-cols-4 padding-bottom'>
            {items?.map((item, index) => {
                const key = item?.id ?? index
                return (
                    <Fragment key={key}>
                        <ManufacturerItemGridImage
                            selectedItem={selectedItem}
                            selectedChildItem={selectedChildItem}
                            itemId={item?.id}
                            childImageId={undefined}
                            itemThumbnail={item?.thumbnail}
                            fileType={getFileTypeFromUrl(item)}
                            isLoading={isLoading}
                            onThumbnailClick={onThumbnailClick}
                        />
                        {item?.item_images?.map((itemImage, index) => {
                            const imageKey = key + '_' + itemImage?.id ?? index
                            return (
                                <ManufacturerItemGridImage
                                    key={imageKey}
                                    selectedItem={selectedItem}
                                    selectedChildItem={selectedChildItem}
                                    itemId={item?.id}
                                    childImageId={itemImage.id}
                                    itemThumbnail={itemImage.thumbnail}
                                    fileType={getFileTypeFromUrl(itemImage)}
                                    isLoading={isLoading}
                                    onThumbnailClick={onThumbnailClick}
                                />
                            )
                        })}
                    </Fragment>
                )
            })}
        </div>
    )
}

function ManufacturerItemGridImage({
    selectedItem,
    selectedChildItem,
    itemId,
    childImageId,
    itemThumbnail,
    fileType = 0,
    isLoading = false,
    onThumbnailClick,
}) {
    let selected = selectedItem && selectedItem?.id === itemId
    if (selected) {
        // If selected check the sub items
        selected =
            (selectedChildItem && selectedChildItem?.id === childImageId) ||
            (childImageId === undefined && selectedChildItem === undefined)
    }

    return (
        <div className={`center thumbnail ${selected ? 'border-primary-1' : ''}`}>
            {fileType === fileTypeConst.pdf ? <PictureAsPdfIcon className='thumbnail-overlay' /> : null}
            {fileType === fileTypeConst.youtube ? <YouTubeIcon className='thumbnail-overlay' /> : null}
            {fileType === fileTypeConst.pdf ? <AttachFileIcon className='thumbnail-overlay' /> : null}
            {isLoading ? (
                <Skeleton variant='rectangular' width={'100px'} height={'100px'} />
            ) : itemThumbnail ? (
                fileType === fileTypeConst.youtube ? (
                    <YoutubeLogo
                        width={'100%'}
                        height={'100%'}
                        className='margin-4 clickable'
                        onClick={() => onThumbnailClick(itemId, childImageId)}
                    />
                ) : fileType === fileTypeConst.excel ? (
                    <ExcelLogo
                        width={'100%'}
                        height={'100%'}
                        className='margin-4 clickable'
                        onClick={() => onThumbnailClick(itemId, childImageId)}
                    />
                ) : (
                    <img
                        src={itemThumbnail}
                        className='responsive clickable'
                        alt='thumbnail'
                        onClick={() => onThumbnailClick(itemId, childImageId)}
                    />
                )
            ) : (
                <>
                    {/* no image uploaded so show skeleton */}
                    <Skeleton
                        variant='rectangular'
                        width={'100px'}
                        height={'100px'}
                        onClick={() => onThumbnailClick(itemId, childImageId)}
                    />
                </>
            )}
        </div>
    )
}

/**
 * the main item block
 */
function ManufacturerItemsBlock({ items }) {
    const [selectedItemIndex, setSelectedItemIndex] = useState(-1)
    const [selectedItemChildIndex, setSelectedItemChildIndex] = useState(-1)

    /**
     * find the passed item in the data array
     */
    const getItem = (data, id) => {
        if (!data || id < 0 || id >= data.length) return undefined
        return data[id]
    }

    /**
     * Callback for when the thumbnail is clicked
     */
    const onThumbnailClick = (id, childImageId) => {
        const index = items?.findIndex((x) => x.id === id) ?? -1
        let childIndex = -1
        if (index > -1 && childImageId) {
            childIndex = items[index]?.item_images?.findIndex((x) => x.id === childImageId) ?? -1
        }
        setSelectedItemIndex(index)
        setSelectedItemChildIndex(childIndex)
    }

    useEffect(() => {
        setSelectedItemIndex((currentIndex) => {
            if (!items || currentIndex >= items.length) return -1
            return currentIndex === -1 ? 0 : currentIndex
        })
        setSelectedItemChildIndex(-1)
    }, [items])

    const selectedItem = getItem(items, selectedItemIndex)
    const selectedChildItem = getItem(selectedItem?.item_images, selectedItemChildIndex)

    return (
        <div className='flex-1'>
            <ContentWrapper>
                <div className='grid grid-cols-3 grid-cols-2-md grid-cols-1-sm flex-1'>
                    <ManufacturerItemBlock
                        item={selectedItem}
                        childItem={selectedChildItem}
                        isLoading={items === undefined}
                    />
                    <div className='margin-2 flex-1 grid-span-col-2-md'>
                        <div className='flex-1'>
                            <ManufacturerItemGrid
                                listItems={items}
                                onThumbnailClick={onThumbnailClick}
                                selectedItem={selectedItem}
                                selectedChildItem={selectedChildItem}
                                isLoading={items === undefined}
                            />
                            {items?.length ?? 0 > 0 ? (
                                <div className='text-center-sm text-center-md'>Click on images to see description</div>
                            ) : null}
                        </div>
                    </div>
                </div>
            </ContentWrapper>
        </div>
    )
}
export default ManufacturerItemsBlock
