import React from 'react'

function ErrorBlock({ useLargeFonts = false, fillSpace = false, error = undefined }) {
    error ??= 'Opps, something went wrong!'

    const boxSize = fillSpace ? '' : ''
    const fontSizeLeft = useLargeFonts ? 'headline-large' : 'body-large'
    const fontSizeRight = useLargeFonts ? 'title-medium' : 'label-medium'

    return (
        <div className={`center flex-container  ${boxSize}`}>
            <div className='center flex-container flex-row flex-center-items error-text'>
                <span className={`${fontSizeLeft} flex-0 padding border-right-error-4 flex-center-self`}>Error</span>
                <span className={`${fontSizeRight} padding flex-center-self`}>{error}</span>
            </div>
        </div>
    )
}

export default ErrorBlock
