import React, { useState } from 'react'
import { NavLink } from 'react-router-dom'
import YouTubeIcon from '@mui/icons-material/YouTube'
import FacebookIcon from '@mui/icons-material/Facebook'
import InstagramIcon from '@mui/icons-material/Instagram'
import MenuIcon from '@mui/icons-material/Menu'
import HomeIcon from '@mui/icons-material/Home'

import './Header.css'
import logo from '../../assets/png/logo.png'
import instagram_qr from '../../assets/png/social/instagram_qr.png'

import useAppContext from '../../context/useAppContext'
import IconButton from '../IconButton'
import ConfirmDialog from '../ConfirmDialog'

/**
 * JSX for the social links on the left of the header
 */
function HeaderSocialLinks({ className = '' }) {
    const { toggleDrawerLeft } = useAppContext()
    const [socialDialogType, setSocialDialogType] = useState(0)
    const [socialDialogTitle, setSocialDialogTitle] = useState('')
    const [socialDialogImage, setSocialDialogImage] = useState(undefined)

    /**
     * Called when a social link is clicked, pass zero to hid the popup
     */
    const socialClick = (id) => {
        setSocialDialogType(id)
        switch (id) {
            case 1:
                setSocialDialogTitle('Facebook')
                setSocialDialogImage(undefined)
                break
            case 2:
                setSocialDialogTitle('Youtube')
                setSocialDialogImage(undefined)
                break
            case 3:
                setSocialDialogTitle('Instagram')
                setSocialDialogImage(instagram_qr)
                break
            default:
                setSocialDialogTitle('')
                setSocialDialogImage(undefined)
        }
    }

    /**
     * Called when the yes button is clicked on the social popup
     */
    const socialYesClick = () => {
        console.log('ddd')
        let link = undefined
        switch (socialDialogType) {
            case 1:
                link = undefined
                break
            case 2:
                link = undefined
                break
            case 3:
                link = process.env.REACT_APP_SOCIAL_INSTAGRAM
                break
        }

        // go to the correct site and close the popup
        if (link) location.assign(link)
        socialClick(0)
    }

    // https://mui.com/material-ui/material-icons/
    return (
        <div className={`flex-1 flex-container flex-row header-links ${className}`}>
            <div
                className='hamburger visible-sm'
                onClick={() => {
                    toggleDrawerLeft()
                }}
            >
                <IconButton title='Menu'>
                    <MenuIcon className='margin-inline' fontSize='medium' />
                </IconButton>
            </div>
            <div className='flex-center-self flex-1 text-center'>
                <IconButton
                    title='Facebook'
                    className='margin-inline margin-inline-2-sm'
                    onClick={() => socialClick(1)}
                >
                    <FacebookIcon fontSize='medium' />
                </IconButton>
                <IconButton title='Youtube' className='margin-inline margin-inline-2-sm' onClick={() => socialClick(2)}>
                    <YouTubeIcon fontSize='medium' />
                </IconButton>
                <IconButton
                    title='Instagram'
                    className='margin-inline margin-inline-2-sm'
                    onClick={() => socialClick(3)}
                >
                    <InstagramIcon fontSize='medium' />
                </IconButton>
            </div>
            <div className='visible-sm'>
                <NavLink to='/' className='hidden'>
                    <IconButton title='Home'>
                        <HomeIcon className='margin-inline' fontSize='medium' />
                    </IconButton>
                </NavLink>
            </div>
            {socialDialogType > 0 ? (
                <ConfirmDialog
                    isOpen={socialDialogType > 0}
                    title={socialDialogTitle}
                    onYesClick={socialYesClick}
                    onNoClick={() => socialClick(0)}
                    onClose={() => socialClick(0)}
                    yesLabel={`Visit ${socialDialogTitle}`}
                    noLabel='Close'
                    yesDisabled={socialDialogImage === undefined}
                >
                    {socialDialogImage ? (
                        <img
                            src={socialDialogImage}
                            alt={`${socialDialogTitle} QR code`}
                            className='responsive center'
                        />
                    ) : (
                        <h1>Coming soon</h1>
                    )}
                </ConfirmDialog>
            ) : null}
        </div>
    )
}

/**
 * JSX for the contact details on the right of the header
 */
function HeaderContactInfo({ className = '' }) {
    return (
        <div className={`app-header-contact flex-1 text-right ${className}`}>
            <ul className='align-right margin margin-2-sm body-small'>
                <li>TEL: {process.env.REACT_APP_TEL}</li>
                <li>FAX: {process.env.REACT_APP_FAX}</li>
                <li>
                    <a
                        href={`mailto:${process.env.REACT_APP_EMAIL}?subject=Mail from ${process.env.REACT_APP_SITE_NAME}`}
                        rel='noindex'
                    >
                        {process.env.REACT_APP_EMAIL}
                    </a>
                </li>
            </ul>
        </div>
    )
}
/**
 * JSX for the company logo
 */
function HeaderLogo({ className = '' }) {
    return (
        <div className={`flex-2 text-center ${className}`}>
            <NavLink to='/'>
                <img
                    src={logo}
                    className='responsive header-logo margin-2-sm center'
                    alt={process.env.REACT_APP_SITE_NAME}
                />
            </NavLink>
        </div>
    )
}

/**
 * The sites main header
 */
function Header() {
    return (
        <header className='app-header flex-container flex-row flex-center-items flex-column-sm padding'>
            <HeaderSocialLinks />
            <HeaderLogo />
            <HeaderContactInfo />
        </header>
    )
}

export default Header
