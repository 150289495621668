import { Outlet } from 'react-router-dom'

import Footer from '../components/layout/Footer'
import Header from '../components/layout/Header'
import Navbar from '../components/layout/Navbar'

import '../css/theme.css'
import './Layout.css'
import ErrorBoundary from '../components/errors/ErrorBoundary'
import ErrorBlock from '../components/errors/ErrorBlock'

function MainLayout() {
    return (
        <div className='app-wrapper'>
            <Header />
            <Navbar />
            <div className='app-content'>
                <ErrorBoundary fallback={<ErrorBlock useLargeFonts={true} fillSpace={true} />}>
                    <Outlet />
                </ErrorBoundary>
            </div>
            <Footer />
        </div>
    )
}

export default MainLayout
