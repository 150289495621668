import { Suspense, lazy } from 'react'
import { Routes, Route } from 'react-router-dom'
import { AnimatePresence } from 'framer-motion'

import MainLayout from './layouts/MainLayout'
import MainRoute from './routes/MainRoute'
import LoadingSpinner from './components/LoadingSpinner'

const AdminRoute = lazy(() => import('./routes/AdminRoute'))

function App() {
    return (
        <AnimatePresence mode='wait'>
            <Routes>
                <Route path={'*'} element={<MainLayout />}>
                    <Route path={'*'} element={<MainRoute />} />
                </Route>
                <Route
                    path={'/admin/*'}
                    element={
                        <Suspense fallback={<LoadingSpinner fillSpace={true} />}>
                            <AdminRoute />
                        </Suspense>
                    }
                />
            </Routes>
        </AnimatePresence>
    )
}

export default App
