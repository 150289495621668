import React from 'react'
import { useParams, Navigate } from 'react-router-dom'

import MetaData from '../components/MetaData'
import ContentWrapper from '../components/ContentWrapper'
import LoadingSpinner from '../components/LoadingSpinner'
import ErrorBoundary from '../components/errors/ErrorBoundary'
import ErrorBlock from '../components/errors/ErrorBlock'
import ManufacturerItemsBlock from '../components/manufacturer/ManufacturerItemsBlock'
import MarkdownWrapper from '../components/MarkdownWrapper'

import { useManufacturerSectionByRouteQuery } from '../hooks/useRequestQuery'

/**
 * About info block
 */
function ManufacturerInfoBlock({ data }) {
    return data?.info_markdown ? (
        <div className='card'>
            <div className='card-content'>
                <h1 className='headline-large'>{data?.title}</h1>
                <MarkdownWrapper className='body-medium'>{data?.info_markdown}</MarkdownWrapper>
            </div>
        </div>
    ) : (
        <div className='padding margin'>
            <h1 className='headline-large padding-bottom-4'>{data?.title}</h1>
        </div>
    )
}

function ManufacturerSectionPage() {
    const { manufacturerRoute, sectionRoute } = useParams()
    const { isLoading, isError, data } = useManufacturerSectionByRouteQuery(manufacturerRoute, sectionRoute)

    // if we cant load the data, re-direct the user
    if (!sectionRoute || !manufacturerRoute || isError) {
        return <Navigate to='/' />
    }

    const section = data?.data

    return (
        <ContentWrapper>
            {isLoading || isError ? null : (
                <MetaData
                    noIndex={false}
                    title={`${section?.title} : ${section?.manufacturer?.title}`}
                    description={`${section?.title} - ${section?.manufacturer?.title}`}
                />
            )}
            <div className='flex-container flex-column width-100'>
                {isLoading ? (
                    <div>
                        <LoadingSpinner fillSpace={true} />
                    </div>
                ) : (
                    <ManufacturerInfoBlock data={section} />
                )}
                <hr className='border-top-primary-4' />
                {/* Bottom product section */}
                <ErrorBoundary fallback={<ErrorBlock fillSpace={true} />}>
                    <ManufacturerItemsBlock items={section?.items} />
                </ErrorBoundary>
            </div>
        </ContentWrapper>
    )
}

export default ManufacturerSectionPage
