import React from 'react'

/**
 * The JSX for the youtube embedded player
 */
function YoutubePlayer({ url }) {
    var n = url.lastIndexOf('/')
    if (n === -1) return <div className='on-error-text on-error-container'>Invalid YouTube url</div>

    const embedUrl = 'https://www.youtube.com/embed/' + url.substring(n + 1)

    return (
        <iframe
            src={embedUrl}
            title='YouTube video player'
            allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share'
            allowFullScreen
            className='player'
        ></iframe>
    )
}

export default YoutubePlayer
