const getNavLink = (id, title, route) => {
    return { id, title, route }
}

/**
 * Gets the nav links for the passed item
 */
const getNavLinks = (item) => {
    const result = []

    const baseRoute = item.route ? item.route : item.id
    // route = manufacture/about
    for (let i = 0; i < item.sections?.length; i++) {
        const section = item.sections[i]
        if (!section) continue
        if (!section?.id) continue

        // route = manufacture/item/id/slug
        result.push(
            getNavLink(
                section?.id,
                section?.title,
                `/${encodeURIComponent(baseRoute)}/${encodeURIComponent(section?.route)}`
            )
        )
    }

    return result
}

export default getNavLinks
