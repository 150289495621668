import * as React from 'react'
import Dialog from '@mui/material/Dialog'
import DialogContent from '@mui/material/DialogContent'
import DialogTitle from '@mui/material/DialogTitle'
import { Button, DialogActions } from '@mui/material'

export default function PopupDialog({ isOpen, title, onClose, maxWidth = 'lg', children }) {
    return (
        <Dialog open={isOpen} onClose={onClose} maxWidth={maxWidth}>
            <DialogTitle id='alert-dialog-title'>{title}</DialogTitle>
            <DialogContent>{children}</DialogContent>
            <DialogActions>
                <Button onClick={onClose}>Close</Button>
            </DialogActions>
        </Dialog>
    )
}
