import React from 'react'
import ReactMarkdown from 'react-markdown'

function MarkdownWrapper({ className = '', children }) {
    if (typeof children === 'string') {
        // sanitize the mark down
        // replace {LF} with {SPACE}{SPACE}{LF} for new lines
        // https://www.markdownguide.org/basic-syntax/
        children = children.replaceAll('\n', '  \n')
    }
    return <ReactMarkdown className={`markdown ${className}`}>{children}</ReactMarkdown>
}

export default MarkdownWrapper
