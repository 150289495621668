import React, { useEffect, useState } from 'react'
import { NavLink } from 'react-router-dom'

import './Navbar.css'

import getNavLinks from '../../helpers/getNavLinks'
import NavDrawer from './NavDrawer'
import LoadingSpinner from '../LoadingSpinner'
import useAppContext from '../../context/useAppContext'
import ErrorBlock from '../errors/ErrorBlock'

function NavItem({ item, onMenuStateChange, index, activeMenuIndex }) {
    const [isActive, setIsActive] = useState(false)

    useEffect(() => {
        setIsActive(index === activeMenuIndex)
    }, [index, activeMenuIndex])

    const onHoverEffect = (state) => {
        onMenuStateChange(state ? index : undefined)
    }

    const navlinks = getNavLinks(item)
    const isPrimary = item?.is_primary

    return (
        <li
            className={`${isActive ? 'nav-item active' : 'nav-item'}  ${isPrimary ? 'nav-primary' : ''}`}
            onMouseEnter={() => onHoverEffect(true)}
            onMouseLeave={() => onHoverEffect(false)}
        >
            <div onClick={() => onHoverEffect(!isActive)}>{item.title}</div>
            <ul className='dropdown'>
                {navlinks?.map((subitem) => {
                    const isPrimary = subitem?.is_primary
                    return (
                        <li key={subitem.id} className={`dropdown-item ${isPrimary ? 'dropdown-primary' : ''}`}>
                            <NavLink to={subitem.route}>{subitem.title}</NavLink>
                        </li>
                    )
                })}
            </ul>
        </li>
    )
}

function Navbar() {
    const [activeMenuIndex, setActiveMenuIndex] = useState(undefined)

    const { manufacturers, setActiveManufacturersId } = useAppContext()
    const { isLoading, isError, data, error } = manufacturers

    const onMenuStateChange = (index) => {
        setActiveMenuIndex(index)
        setActiveManufacturersId(index)
    }

    if (isLoading)
        return (
            <div className='text-center'>
                <LoadingSpinner />
            </div>
        )

    if (isError) {
        console.log(error)
        return (
            <div className='text-center'>
                <ErrorBlock />
            </div>
        )
    }

    return (
        <>
            <nav className='hidden-sm'>
                <ul className='nav-menus flex-container flex-row'>
                    {data?.data?.map((item) => {
                        return (
                            <NavItem
                                key={item?.id}
                                index={item?.id}
                                item={item}
                                onMenuStateChange={onMenuStateChange}
                                activeMenuIndex={activeMenuIndex}
                            />
                        )
                    })}
                </ul>
            </nav>
            <NavDrawer />
        </>
    )
}

export default Navbar
